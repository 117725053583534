(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("angular"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define("micrositeMain", ["angular", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["micrositeMain"] = factory(require("angular"), require("jQuery"));
	else
		root["micrositeMain"] = factory(root["angular"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 