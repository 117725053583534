if (window.top !== window.self) {
    window.top.location = 'https://www.homevalueleads.com/iframe-fix';
}

require([
    'angular',
    'jquery',
    './angular/site/microsite',
], function (angular, $) {
    'use strict';

    if (typeof Raven != 'undefined' && Raven) {
        Raven.addPlugin(Raven.Plugins.Angular, angular);
    }

    $(document).ready(function () {
        angular.bootstrap(document, ['micrositeApp']);
    });
});
